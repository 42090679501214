<template>
  <div id="signup-modal" class="custom-modal">
    <div
      id="signup-modal-close"
      v-click-outside="closeModal"
      class="custom-modal-content wd-40p text-black"
    >
      <h6 class="mb-5 text-uppercase text-center font-weight-bold">
        Create an account
      </h6>
      <form
        @submit="signInClicked"
        novalidate
        class="flex-column align-items-center"
      >
        <div class="w-100 mx-auto">
          <div class="row mb-3 bg-white expand-p3 p-3">
            <div class="col-6 mb-3 text-align-right-on-arabic">
              <label for="password">Email</label>
              <input
                id="password"
                name="email"
                type="email"
                class="form-control"
                v-model="email"
                required
              />
            </div>
            <div class="col-6 mb-3 text-align-right-on-arabic">
              <label for="password">Password</label>
              <input
                name="password"
                type="password"
                class="form-control"
                v-model="password"
                required
              />
            </div>
            <div class="col-12 mb-2">
              <center>
                <div class="text-danger my-2" v-if="this.errorMessage != ''">
                  {{ this.errorMessage }}
                </div>
              </center>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="rounded-button bg-primary text-neutral-light mx-auto"
          value="submit"
          :disabled="submitting"
        >
          <img
            v-show="submitting"
            v-bind:src="require('../assets/images/loading-white.gif')"
            class="mx-2"
          />
          Sign In
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "login-modal",
  props: ["redirect-to"],
  data: function () {
    return {
      email: null,
      password: "",
      submitting: false,
      errorMessage: null,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    signInClicked: function (e) {
      this.submitting = true;
      axios
        .post(
          `${process.env.VUE_APP_BACKEND_BASE_URL}/users/sign_in`,
          {
            user: {
              email: this.email,
              password: this.password,
            },
          },
          {
            headers: {
              Accept: "application/json",
            },
            withCredentials: true,
          }
        )
        .then(
          function (response) {
            this.submitting = false;
            if (response.data.success === "0") {
              this.errorMessage = `Errors: ${response.data.error_messages.join(
                ", "
              )}`;
            } else {
              this.$store.commit("auth/setUser", response.data);
              this.$emit("close-modal");
            }
          }.bind(this)
        );

      e.preventDefault();
    },
    setNewNumber(phoneObject) {
      this.phoneNumber = phoneObject.formatted;
      this.phoneObject = phoneObject;
    },
  },
};
</script>

<style scoped>
.error {
  border-color: darkred !important;
}
</style>

<template>
  <div class="w-100 mt-5" style="padding: 0 1.3rem !important">
    <router-link
      v-if="!isExternal"
      :to="{ name: linkTo }"
      :class="['side-bar-link', active ? '' : 'side-bar-link__not-active']"
    >
      <h1 class="side-bar-link__text m-0 font-weight-500 text-neutral-light">
        {{ name }}
      </h1>
      <slot name="icon" class="side-bar-link__icon" />
    </router-link>
    <a
      v-else
      :href="linkTo"
      target="_blank"
      :class="['side-bar-link', active ? '' : 'side-bar-link__not-active']"
    >
      <h1 class="side-bar-link__text m-0 font-weight-500 text-neutral-light">
        {{ name }}
      </h1>
      <slot name="icon" class="side-bar-link__icon" />
    </a>
  </div>
</template>

<script>
export default {
  name: "SideBarLink",
  props: {
    name: String,
    link: String,
  },
  computed: {
    linkTo() {
      if (!this.link) {
        return "";
      }

      return this.link;
    },
    isExternal() {
      return this.link && this.link.includes("http");
    },
    active() {
      return this.$route.name === this.link;
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar-link {
  filter: brightness(1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--neutral-light);
  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &__not-active {
    opacity: 0.7;
  }

  padding-bottom: 0.5rem !important;
  cursor: pointer;

  &__text {
    font-weight: 400;
    font-size: 1.2rem !important;
    margin: 0 !important;

    /* @media (min-width: 768px) {
      font-size: 1.2rem !important;
    } */
  }

  &__icon {
    fill: white;
    height: 2rem;

    @media (min-width: 768px) {
      height: 1.7rem;
    }
  }
}
</style>

import cloneDeep from "lodash/cloneDeep";
import axios from "axios";
import router from "../router/index";

const state = {
  rooms: [],
  systemTutorials: [],
  clipboard: null,
};

const getters = {
  getRoomById: (state) => (id) => {
    return state.rooms.find((room) => room.id === (id || state.rooms[0].id));
  },
};

const mutations = {
  addRoom: (state, payload) => {
    state.rooms.push(payload);
  },
  initializeRooms: (state, payload) => {
    let projectRoom = state.rooms.filter((r) => r.typeName === "Backend")[0];
    let toBeAdded = [...payload];
    if (projectRoom) {
      toBeAdded.push(projectRoom);
    }
    state.rooms = toBeAdded;
  },
  setTutorials: (state, rooms) => {
    let roomSystems = rooms.map((r) => r.systems).flat();

    state.systemTutorials = roomSystems.reduce((acc, rs) => {
      return {
        ...acc,
        [rs.category]: {
          title: rs.name,
          tutorialUrl: rs.tutorial_url,
          shown: false,
          category: rs.category,
        },
      };
    }, {});
  },
  setTutorialShown: (state, category) => {
    state.systemTutorials[category].shown = true;
  },
  copy: (state, roomId) => {
    state.clipboard = cloneDeep(state.rooms.find((r) => r.id === roomId));
  },
  apply: (state, roomId) => {
    state.rooms.find((r) => r.id === roomId).systems = cloneDeep(
      state.clipboard
    ).systems;
  },
  reset: (state, payload) => {
    state.rooms.find((r) => r.id === payload.roomId).systems =
      payload.defaultSystems;
  },
  resetRoomsAnswers(state, rooms) {
    rooms.forEach((r) => {
      let stateRooms = state.rooms.filter((room) => room.name === r.name);

      stateRooms.forEach((sr) => {
        sr.systems.forEach((s) => {
          s.questions
            .filter((q) => r.answer_options.includes(q.answer_id))
            .forEach((q) => {
              q.answer_id = null;
            });
        });
      });
    });
  },
  setRoomAnswer(state, payload) {
    state.rooms
      .find((r) => r.id === payload.roomId)
      .systems[payload.systemIndex].questions.find(
        (q) => q.id === payload.questionId
      ).answer_id = payload.answerId;
  },
};

const actions = {
  resetRoom({ commit, rootState }, roomId) {
    commit("reset", {
      roomId: roomId,
      defaultSystems: cloneDeep(rootState.project.dashboardSystems),
    });
  },
  initializeRooms({ commit, rootState }, payload) {
    let rooms = payload
      .filter((r) => r.typeName !== "Backend")
      .map((room) => {
        return {
          id: room.id,
          systems: cloneDeep(
            rootState.project.dashboardSystems
              .filter((s) => room.availableSystems.includes(s.id))
              .map((s) => {
                let system = s;

                system.questions = system.questions.map((q) => {
                  return {
                    ...q,
                    answer_id: null,
                  };
                });
                return system;
              })
          ),
          ...room,
        };
      });

    let projectRoom = rootState.dashboard.rooms.find(
      (r) => r.name === "Project"
    );
    let payloadProjectRoom = payload.find((r) => r.typeName === "Backend");

    if (payloadProjectRoom) {
      projectRoom = {
        ...projectRoom,
        id: payloadProjectRoom.id,
        typeValue: payloadProjectRoom.typeValue,
        typeName: payloadProjectRoom.typeName,
      };
      rooms.push(projectRoom);
    }

    commit("initializeRooms", rooms);
    commit("setTutorials", rooms);
  },
  async initializeProjectRoom({ commit, rootState }) {
    let room = {
      name: "Project",
      systems: cloneDeep(
        rootState.project.projectSystems.map((s) => {
          s.questions = s.questions.map((q) => {
            return {
              ...q,
              answer_id: null,
            };
          });
          return s;
        })
      ),
    };

    commit("initializeRooms", [room]);
  },
  addRoom({ commit, rootState }, room) {
    let roomToBeAdded = {
      id: room.id,
      systems: cloneDeep(
        rootState.project.dashboardSystems.map((s) => {
          let system = s;

          system.questions = system.questions.map((q) => {
            return {
              ...q,
              answer_id: null,
            };
          });
          return system;
        })
      ),
      ...room,
    };

    commit("addRoom", roomToBeAdded);
  },
  submit({ commit, rootState }) {
    commit.rooms;

    let formattedRooms = rootState.dashboard.rooms.map((room) => {
      let answers = room.systems.map((s) =>
        s.questions.map((q) => q.answer_id)
      );

      answers = answers.flat().filter((a) => a);

      return {
        id: room.id,
        answers: answers,
      };
    });

    let payload = {
      project_name: rootState.project.name,
      rooms: formattedRooms,
    };

    axios
      .post(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/api/projects/submit_answers`,
        payload,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        router.push({
          name: "Cart",
          params: { total: response.data.total },
        });
      });
  },
  async deleteAnswers({ commit }, payload) {
    commit;
    return axios
      .delete(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/api/projects/me/answers`,
        {
          withCredentials: true,
          data: payload,
        }
      )
      .then((response) => {
        commit("resetRoomsAnswers", response.data);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import axios from "axios";
import router from "../router/index";

const state = {
  wizardSteps: [],
};

const getters = {};

const mutations = {
  setWizard(state, questions) {
    let stepsObject = {};

    questions.forEach((q) => {
      if (!stepsObject[q.wizard_step]) stepsObject[q.wizard_step] = {};

      stepsObject[q.wizard_step].id = q.wizard_step;
      stepsObject[q.wizard_step].image = q.wizard_image;

      if (!stepsObject[q.wizard_step].questions)
        stepsObject[q.wizard_step].questions = [];

      q.rooms = [];

      stepsObject[q.wizard_step].questions.push(q);
      stepsObject[q.wizard_step].questions.sort((a, b) => {
        return (
          (a.show_on_answer_option_id || 0) - (b.show_on_answer_option_id || 0)
        );
      });
    });

    state.wizardSteps = Object.values(stepsObject);
  },
  addRoomToAnswer(state, data) {
    state.wizardSteps
      .find((s) => s.questions.some((q) => q.id === data.questionId))
      .questions.find((q) => q.id === data.questionId)
      .rooms.push(data.roomId);
  },
  removeRoomFromAnswer(state, data) {
    let question = state.wizardSteps
      .find((s) => s.questions.some((q) => q.id === data.questionId))
      .questions.find((q) => q.id === data.questionId);
    question.rooms = question.rooms.filter((r) => r !== data.roomId);
  },
};

const actions = {
  submit({ state, rootState }) {
    let answers = [];

    state.wizardSteps.forEach((s) => {
      let mappedQuestions = s.questions.map((q) => {
        return {
          answer_value: q.answer,
          rooms: q.rooms,
        };
      });

      answers.push(...mappedQuestions);
    });

    let projectRoom = rootState.dashboard.rooms.find(
      (r) => r.typeName === "Backend"
    );
    projectRoom.systems
      .map((s) => s.questions)
      .flat()
      .forEach((q) => {
        if (q.answer_id) {
          answers.push({
            answer_value: q.answer_id,
            rooms: [projectRoom.id],
          });
        }
      });

    let payload = {
      project_name: rootState.project.name,
      answers: answers,
    };

    axios
      .post(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/api/projects/submit_wizard`,
        payload,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        router
          .push({
            name: "Cart",
            params: { total: response.data.total, isWizard: true },
          })
          .catch(() => router.go());
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

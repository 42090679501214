<template>
  <vue-tel-input :value="value" @input="emitInput"></vue-tel-input>
</template>

<script>
import { VueTelInput } from "vue-tel-input";

export default {
  name: "PhoneInput",
  props: ["value"],
  components: { VueTelInput },
  methods: {
    emitInput(number, phoneObject) {
      this.$emit("input", phoneObject);
    },
  },
};
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>

import axios from "axios";

const state = {
  projects: [],
};

const getters = {};

const mutations = {
  setProjects(state, data) {
    state.projects = data;
  },
};

const actions = {
  getProjects({ commit }, developerId) {
    axios
      .get(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/api/property_developers/${developerId}/property_projects`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        commit("setProjects", response.data.projects);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <main class="body--light">
    <div id="parent-container" class="parent-container">
      <div class="parent-container__header">
        <div
          class="parent-container__header__big-section order-1 order-md-0 position-relative"
        >
          <slot name="header">
            <div class="d-none d-md-flex justify-start gap-3 wd-40p">
              <router-link to="/about">
                <span class="text-grey text-left font-weight-400 subtitle-text"
                  >About Us
                </span>
              </router-link>
              <span class="text-grey text-left font-weight-400 subtitle-text"
                >|
              </span>
              <a>
                <span class="text-grey text-left font-weight-400 subtitle-text"
                  >Partners
                </span>
              </a>
            </div>
          </slot>
        </div>

        <div
          class="d-flex align-items-center parent-container__header__small-section position-relative order-0 order-md-1"
        >
          <div
            class="d-flex flex-row align-items-center justify-content-center gap-1"
          >
            <div
              v-if="!user || !user.name"
              class="d-none d-md-flex"
              style="gap: 0.5rem"
            >
              <button
                @click="showSignUp = true"
                class="bg-primary text-neutral-light"
              >
                Sign Up
              </button>
              <button @click="showSignIn = true" class="text-primary">
                Login
              </button>
            </div>
            <user-navbar-item v-if="user && user.name" :user="user" />
          </div>

          <div
            class="d-flex d-md-none justify-content-between align-items-center wd-100p px-2"
          >
            <a class="wd-30p" href="/shop"
              ><inline-svg
                class="text-neutral-light logo"
                :src="require('../assets/space7_logo.svg')"
              ></inline-svg
            ></a>

            <v-icon
              color="#fff"
              size="30px"
              id="sidebar-toggler"
              ref="sidebarToggler"
              class="opacity-100"
              @click="showSidebar = !showSidebar"
              >mdi-menu</v-icon
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column align-items-center parent-container__sidebar pt-3 bg-y-gradient"
        :class="{ 'parent-container__sidebar-active': showSidebar }"
        v-click-outside="clickedOutside"
      >
        <a class="wd-60p d-none d-md-block" style="min-height: 40px" href="/"
          ><inline-svg
            class="text-neutral-light"
            :src="require('../assets/space7_logo.svg')"
          ></inline-svg
        ></a>
        <div
          class="overflow-y-auto w-100 mt-5 px-2"
          v-if="!$route.meta || !$route.meta.customSidebar"
        >
          <side-bar-link
            v-for="item in filteredSidebarItems"
            :key="item.name"
            :name="item.name"
            :link="item.route"
          >
            <template v-slot:icon>
              <v-icon color="#fff" size="28px">{{ item.iconName }}</v-icon>
            </template>
          </side-bar-link>
        </div>
        <router-view class="overflow-y-auto w-100 mt-5" name="sidebar" v-else />
      </div>

      <div class="parent-container__main">
        <transition mode="out-in" :name="`slide-left`">
          <router-view @signup="showSignUp = true" class="flex-2" />
        </transition>
      </div>
    </div>
    <signup-modal
      @success="
        showSignUp = false;
        showSignIn = true;
      "
      v-if="showSignUp"
      @close-modal="showSignUp = false"
    />
    <login-modal v-if="showSignIn" @close-modal="showSignIn = false" />
    <footer class="parent-container__footer px-4">
      <div></div>
      <div class="d-flex flex-column align-items-end justify-content-center">
        <inline-svg
          class="text-neutral-light logo"
          :src="require('/src/assets/space7_logo.svg')"
        />
        <div class="subtitle-text font-weight-500 text-neutral-light">
          Copyright 2022
        </div>
      </div>
    </footer>
  </main>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import SideBarLink from "../components/SideBarLink.vue";
import SignupModal from "@/components/SignUpModal";
import LoginModal from "@/components/LoginModal";
import UserNavbarItem from "@/components/UserNavbarItem";

export default {
  name: "Home",
  components: {
    UserNavbarItem,
    LoginModal,
    SignupModal,
    InlineSvg,
    SideBarLink,
  },
  mounted() {
    document.getElementById(
      "parent-container"
    ).style.height = `${window.innerHeight}px`;

    window.addEventListener("resize", () => {
      document.getElementById(
        "parent-container"
      ).style.height = `${window.innerHeight}px`;
    });
  },
  data: function () {
    return {
      showSidebar: false,
      showSignUp: false,
      showSignIn: false,
      sidebarItems: [
        {
          name: "My profile",
          route: "profile",
          iconName: "",
          requiresUser: true,
        },
        {
          name: "My projects",
          route: "projects",
          iconName: "",
          requiresUser: true,
        },
        {
          name: "Create New Project",
          route: "CreateProject1",
          iconName: "mdi-plus-circle-outline",
        },
        {
          name: "Gift Selector",
          route: null,
          iconName: "mdi-gift-outline",
        },
        {
          name: "Store",
          route: "https://space7-egypt-staging.herokuapp.com",
          iconName: "mdi-cart-outline",
        },
        {
          name: "Find your developer",
          route: "Developers",
          iconName: "mdi-domain",
        },
        {
          name: "Tutorials",
          route: null,
          iconName: "mdi-play-circle-outline",
        },
        {
          name: "Request Support",
          route: "https://www.space7.co/contact",
          iconName: "mdi-face-agent",
        },
      ],
    };
  },
  methods: {
    clickedOutside(e) {
      if (
        e.target.id !== "sidebar-toggler" &&
        !this.isDescendant(this.$refs.sidebarToggler, e.target) &&
        this.showSidebar
      ) {
        this.showSidebar = false;
        e.stopPropagation();
        e.preventDefault();
      }
    },
    isDescendant(parent, child) {
      var node = child.parentNode;
      while (node != null) {
        if (node == parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
  },
  computed: {
    user() {
      console.log(this.$store.state.auth.user);
      return this.$store.state.auth.user;
    },
    filteredSidebarItems() {
      return this.sidebarItems.filter((i) => {
        if (i.requiresUser) {
          if (!this.user?.name) {
            return false;
          }
        }

        return true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 600ms ease-in-out;
}
.slide-left-enter-from {
  transform: translateX(100%);
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.parent-container {
  display: grid;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  grid-template-columns: 1fr 6fr;
  grid-template-rows: 1fr 15fr;
  background: var(--neutral-dark);
  grid-template-areas:
    "sidebar header"
    "sidebar main";

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 9fr;
    background: var(--gradient-secondary);
    grid-template-areas:
      "header"
      "main";
  }

  &__sidebar {
    grid-area: sidebar;
    min-width: 18rem;
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 2rem 0 !important;

    @media screen and (max-width: 767px) {
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 80%;
      height: 100%;
      transition: transform 150ms ease-in-out;
      transform: translateX(-100%);

      &-active {
        transform: translateX(0);
      }
    }

    @keyframes FadeIn {
      0% {
        opacity: 0;
        transform: scale(0.1);
      }

      85% {
        opacity: 1;
        transform: scale(1.05);
      }
      100% {
        transform: scale(1);
      }
    }

    @for $i from 1 through 10 {
      &__item:nth-child(#{$i}n) {
        animation-delay: #{$i * 0.25}s;
      }
    }

    &__item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--neutral-light);
      animation: FadeIn 0.5s linear;
      animation-fill-mode: both;
      padding-bottom: 0.5rem !important;
      cursor: pointer;

      &__text {
        font-weight: 500;
        font-size: 1rem !important;
        margin: 0 !important;

        @media (min-width: 768px) {
          font-size: 90% !important;
        }
      }

      &__icon {
        fill: white;
        height: 2rem;

        @media (min-width: 768px) {
          height: 1.7rem;
        }
      }
    }

    &__item + &__item {
      margin-top: 1.5rem;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    grid-area: header;
    background: transparent;

    padding: 1rem 1rem 0.5rem;

    @media (min-width: 768px) {
      padding: 1rem 2rem;
      gap: 1rem !important;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;
      background: #161616;
    }

    &__small-section {
      height: 100%;
      width: 100%;

      @media screen and (min-width: 768px) {
        width: max-content;
      }
    }

    &__big-section {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: unset;
        flex: 1;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    grid-area: main;
    overflow-y: scroll;
  }

  &__footer {
    padding: 1rem 2rem;
    background-color: black;
    height: 20vh;

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white !important;
  }
}

.logo {
  fill: white !important;
}
</style>

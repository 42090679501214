import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import clickOutside from "./directives/click-outside";
import VueYoutube from "vue-youtube";

import "./assets/styles/application.scss";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.directive("click-outside", clickOutside);
Vue.use(VueYoutube);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

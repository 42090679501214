import axios from "axios";
import router from "../router/index";

const state = {
  roomTypes: [],
  selectedRoomTypes: [],
};

const getters = {};

const mutations = {
  setRoomTypes(state, data) {
    state.roomTypes = data;
  },
  addRoom(state, room) {
    state.selectedRoomTypes.push(room);
  },
  removeOneRoom(state, room) {
    let roomIndex = state.selectedRoomTypes.findIndex(
      (r) => r.typeValue === room.typeValue
    );
    state.selectedRoomTypes.splice(roomIndex, 1);
  },
  removeAllRoomsWithType(state, roomType) {
    state.selectedRoomTypes = state.selectedRoomTypes.filter(
      (r) => r.typeValue !== roomType
    );
  },
};

const actions = {
  getRoomTypes({ commit }) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/rooms/room-types`, {
        withCredentials: true,
      })
      .then((response) => {
        commit("setRoomTypes", response.data.room_types);
      });
  },
  async submit({ dispatch, state, rootState }) {
    let payload = {
      project: {
        name: rootState.project.name,
        rooms: state.selectedRoomTypes.map((sr) => {
          return {
            name: sr.name,
            type: sr.typeValue,
          };
        }),
      },
    };

    axios
      .post(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/projects`, payload, {
        withCredentials: true,
      })
      .then((response) => {
        dispatch("dashboard/initializeRooms", response.data.project.rooms, {
          root: true,
        });

        router.replace({ name: "CreateProject8" });
      });
  },
  addRoom({ dispatch }, payload) {
    axios
      .post(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/rooms`, payload, {
        withCredentials: true,
      })
      .then((response) => {
        dispatch("dashboard/addRoom", response.data.room, {
          root: true,
        });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

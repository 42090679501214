import axios from "axios";
import store from "@/store/index";
import router from "@/router/index";

const api = axios.create({
  withCredentials: true,
  headers: {
    common: {
      Accept: "application/json",
      "Content-type": "application/json",
    },
  },
});

api.interceptors.response.use(undefined, function (error) {
  if ([401, 403].includes(error.response.status)) {
    store.commit("auth/removeUser");
    router.replace({ name: "StoreFront" });
  }
  return Promise.reject(error);
});

export { api };

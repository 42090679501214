<template>
  <div id="app">
    <Home data-app />
    <modal
      v-if="showModal"
      :show="showModal"
      @close="showModal = false"
      :hide-close="true"
      style="
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
      "
    >
      <div
        style="
          width: 40%;
          height: 40%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #fff;
          border-radius: 10px;
          border: 1px solid #000;
        "
      >
        <div style="font-weight: bold; font-size: 20px; text-align: center">
          Looks like you have an existing project. Would you like to continue
          with that project?
        </div>

        <div
          style="
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
          "
        >
          <button
            style="
              width: 100px;
              height: 50px;
              background-color: #fff;
              border: 1px solid #000;
              border-radius: 10px;
              margin: 10px;
            "
            @click="handleModalNo"
          >
            No
          </button>
          <button
            style="
              width: 100px;
              height: 50px;
              background-color: #fff;
              border: 1px solid #000;
              border-radius: 10px;
              margin: 10px;
            "
            @click="handleModalYes"
          >
            Yes
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

button:hover {
  cursor: pointer;
  background-color: #000 !important;
  color: #fff !important;
}
</style>

<script>
import Modal from "./components/Modal.vue";
import Home from "./layout/Home";
export default {
  components: { Home, Modal },
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    if (this.alreadyHaveProject) {
      this.showModal = true;
    }
  },

  computed: {
    alreadyHaveProject: function () {
      return this.$store.getters["project/alreadyHaveProject"];
    },
  },

  methods: {
    handleModalNo() {
      this.$store.dispatch("project/clearProject");
      this.$store.state.room.selectedRoomTypes?.forEach((rt) => {
        this.$store.commit("room/removeAllRoomsWithType", rt.typeValue);
      });
      this.showModal = false;
    },
    handleModalYes() {
      const lastVisitedProject =
        this.$store.getters["project/getLastRouteVisited"];
      this.$router.push({ name: lastVisitedProject });
      this.showModal = false;
    },
  },
};
</script>

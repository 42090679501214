import axios from "axios";

const state = {
  developers: [],
};

const getters = {};

const mutations = {
  setDevelopers(state, data) {
    state.developers = data;
  },
};

const actions = {
  getDevelopers({ commit }) {
    axios
      .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/property_developers`, {
        withCredentials: true,
      })
      .then((response) => {
        commit("setDevelopers", response.data.developers);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

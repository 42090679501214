import { categories } from "../utils/constants";
import { api } from "@/utils/axios";

const state = {
  name: "",
  dashboardSystems: [],
  projectSystems: [],
  isWizard: false,
  lastRouteVisited: "",
};

const getters = {
  alreadyHaveProject: (state) => {
    return state.name !== "";
  },
  getLastRouteVisited: (state) => {
    return state.lastRouteVisited;
  },
};

const mutations = {
  setIsWizard(state, isWizard) {
    state.isWizard = isWizard;
  },
  updateName: (state, payload) => {
    state.name = payload;
  },
  setDashboardSystems(state, payload) {
    state.dashboardSystems = payload.map((s) => {
      return {
        ...s,
        questions: s.questions.filter((q) => q.journey_type === "dashboard"),
        active: true,
        name: categories[s.category].name,
        icon: categories[s.category].icon,
        tutorialUrl: s.tutorial_url,
      };
    });
  },
  setProjectSystems(state, payload) {
    state.projectSystems = payload.map((s) => {
      return {
        ...s,
        active: true,
        name: categories[s.category].name,
        icon: categories[s.category].icon,
      };
    });
  },
  resetStore(state) {
    state.name = "";
    state.dashboardSystems = [];
    state.projectSystems = [];
    state.isWizard = false;
  },
  setLastRouteVisited(state, payload) {
    state.lastRouteVisited = payload;
  },
};

const actions = {
  clearProject({ commit }) {
    commit("resetStore");
  },
  updateLastRouteVisited({ commit }, payload) {
    commit("setLastRouteVisited", payload);
  },
  async getProjectSummary({ commit }, groupBySystem = true) {
    commit;

    return api.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/projects/me`, {
      params: {
        groupBySystem: groupBySystem,
      },
      withCredentials: true,
    });
  },
  async getSystems({ commit }) {
    await api
      .get(`${process.env.VUE_APP_BACKEND_BASE_URL}/api/systems`, {
        withCredentials: true,
      })
      .then((response) => {
        commit(
          "setDashboardSystems",
          response.data.filter(
            (s) => !["safety", "network", "home"].includes(s.category)
          )
        );
        commit(
          "setProjectSystems",
          response.data.filter((s) =>
            ["safety", "network"].includes(s.category)
          )
        );
        commit(
          "wizard/setWizard",
          response.data
            .map((s) => {
              return s.questions.map((q) => {
                return {
                  ...q,
                  systemName: s.name,
                  systemCategory: s.category,
                };
              });
            })
            .flat()
            .filter((q) => q.journey_type === "wizard"),
          { root: true }
        );
      });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

<template>
  <div id="signup-modal" class="custom-modal">
    <div
      id="signup-modal-close"
      v-click-outside="closeModal"
      class="custom-modal-content wd-40p text-black"
    >
      <h6 class="mb-5 text-uppercase text-center font-weight-bold">
        Create an account
      </h6>
      <form
        @submit="signupBtnClicked"
        novalidate
        class="flex-column align-items-center"
        :class="{ 'was-validated': wasValidated }"
      >
        <div class="w-100 mx-auto">
          <div class="row mb-3 bg-white expand-p3 p-3">
            <div class="col-12 mb-3 text-align-right-on-arabic">
              <label for="fullName">Full Name</label>
              <input
                id="fullName"
                name="name"
                type="text"
                class="form-control"
                v-model="fullName"
                required
              />
              <div class="invalid-feedback">Please fill out this field.</div>
            </div>
            <div class="col-6 mb-3 text-align-right-on-arabic">
              <label for="password">Email</label>
              <input
                id="password"
                name="email"
                type="email"
                class="form-control"
                v-model="email"
                required
              />
            </div>
            <div class="col-6 mb-3 text-align-right-on-arabic">
              <label for="phone-number">Phone Number</label>
              <phone-input
                class="form-control d-flex p-0"
                :value="phoneNumber"
                @input="setNewNumber"
              />
              <div
                v-if="phoneObject && !phoneObject.valid"
                class="invalid-feedback d-block"
              >
                Please enter a valid phone number
              </div>
            </div>
            <div class="col-6 mb-3 text-align-right-on-arabic">
              <label for="password">Password</label>
              <input
                name="password"
                type="password"
                class="form-control"
                v-model="password"
                required
              />
            </div>
            <div class="col-6 mb-2 text-align-right-on-arabic">
              <label for="passwordconfirm">Confirm Password</label>
              <input
                name="passwordconfirm"
                type="password"
                class="form-control"
                v-model="passwordConfirm"
                required
              />
            </div>
            <div class="col-12 mb-2">
              <center>
                <div class="text-danger my-2" v-if="this.errorMessage != ''">
                  {{ this.errorMessage }}
                </div>
              </center>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="rounded-button bg-primary text-neutral-light mx-auto"
          value="submit"
          :disabled="submitting"
        >
          <img
            v-show="submitting"
            v-bind:src="require('../assets/images/loading-white.gif')"
            class="mx-2"
          />
          Sign Up
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PhoneInput from "@/components/PhoneInput";

export default {
  name: "signup-modal",
  components: { PhoneInput },
  props: ["redirect-to"],
  data: function () {
    return {
      email: null,
      password: "",
      fullName: null,
      passwordConfirm: "",
      company_id: "",
      errorMessage: "",
      submitting: false,
      iti: null,
      phoneNumber: null,
      phoneObject: null,
      wasValidated: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    signupBtnClicked: function (e) {
      this.wasValidated = true;
      if (!this.phoneObject.valid) {
        e.preventDefault();
        return false;
      }

      this.submitting = true;
      axios
        .post(`${process.env.VUE_APP_BACKEND_BASE_URL}/users/`, {
          user: {
            email: this.email,
            password: this.password,
            name: this.fullName,
            password_confirmation: this.passwordConfirm,
            phone_number: this.phoneNumber,
          },
        })
        .then(
          function (response) {
            this.submitting = false;
            if (response.data.success === "0") {
              this.errorMessage = `Errors: ${response.data.error_messages.join(
                ", "
              )}`;
            } else {
              this.$emit("success");
            }
          }.bind(this)
        );

      e.preventDefault();
    },
    setNewNumber(phoneObject) {
      this.phoneNumber = phoneObject.formatted;
      this.phoneObject = phoneObject;
    },
  },
};
</script>

<style scoped>
.error {
  border-color: darkred !important;
}
</style>

import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import dashboard from "./dashboard";
import project from "./project";
import wizard from "./wizard";
import room from "./room";
import auth from "./auth";
import developers from "./propertyDevelopers";
import developerProject from "./developerProjects";
import projectProperty from "./projectProperties";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dashboard,
    project,
    wizard,
    room,
    developers,
    developerProject,
    projectProperty,
    auth,
  },
  plugins: [createPersistedState()],
});

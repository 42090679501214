import axios from "axios";

const state = {
  properties: [],
};

const getters = {};

const mutations = {
  setProperties(state, data) {
    state.properties = data;
  },
};

const actions = {
  getProperties({ commit }, { developerId, propertyId }) {
    axios
      .get(
        `${process.env.VUE_APP_BACKEND_BASE_URL}/api/property_developers/${developerId}/property_projects/${propertyId}/project_properties`,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        commit(
          "setProperties",
          response.data.properties.map((p) => {
            return {
              id: p.id,
              name: p.name,
              logo: p.plan_image,
              size: p.size,
            };
          })
        );
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

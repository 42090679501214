import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue"),
  },
  {
    path: "/",
    name: "StoreFront",
    component: () => import("../views/StoreFront.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/projects",
    name: "projects",
    component: () => import("../views/Projects.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
  },
  {
    path: "/wizard",
    name: "wizard",
    component: () => import("../views/Wizard.vue"),
  },
  {
    path: "/room/:id",
    name: "Room",
    meta: {
      customSidebar: true,
    },
    components: {
      default: () => import("../views/Room.vue"),
      sidebar: () => import("../layout/RoomSidebar.vue"),
    },
  },
  {
    path: "/create-project",
    name: "CreateProject",

    component: () => import("../views/CreateProject.vue"),
    children: [
      {
        path: "1",
        name: "CreateProject1",
        meta: {
          title: "Project Information",
          prev: "",
          next: "2",
        },
        component: () => import("../views/CreateProjectJourney/Create.vue"),
      },
      {
        path: "2",
        name: "CreateProject2",
        meta: {
          title: "Wifi Coverage",
          prev: "1",
          next: "3",
        },
        component: () =>
          import("../views/CreateProjectJourney/WifiCoverage.vue"),
      },
      {
        path: "3",
        name: "CreateProject3",
        meta: {
          title: "Security",
          prev: "2",
          next: "4",
        },
        component: () => import("../views/CreateProjectJourney/Security-1.vue"),
      },
      {
        path: "4",
        name: "CreateProject4",
        meta: {
          title: "Security",
          prev: "3",
          next: "5",
        },
        component: () => import("../views/CreateProjectJourney/Security-2.vue"),
      },
      {
        path: "5",
        name: "CreateProject5",
        meta: {
          title: "Security",
          prev: "4",
          next: "6",
        },
        component: () => import("../views/CreateProjectJourney/Security-3.vue"),
      },
      {
        path: "6",
        name: "CreateProject6",
        meta: {
          mini: true,
          title: "Select Project Space Types",
          prev: "5",
          next: "7",
        },
        component: () =>
          import("../views/CreateProjectJourney/SelectSpaces.vue"),
      },
      {
        path: "7",
        name: "CreateProject7",
        meta: {
          mini: false,
          title: "Edit Room Names",
          prev: "6",
          next: "8",
        },
        component: () => import("../views/CreateProjectJourney/EditNames.vue"),
      },
      {
        path: "8",
        name: "CreateProject8",
        meta: {
          mini: false,
          title: "Select a Journey",
          hideNavigation: true,
        },
        component: () =>
          import("../views/CreateProjectJourney/SelectJourney.vue"),
      },
    ],
  },
  {
    path: "/developers",
    name: "Developers",
    component: () => import("../views/PropertyDevelopers"),
  },
  {
    path: "/developers/:developerId/developer-projects",
    name: "DeveloperProjects",
    component: () => import("../views/DeveloperProjects"),
  },
  {
    path: "/developers/:developerId/developer-projects/:projectId/properties",
    name: "ProjectProperties",
    component: () => import("../views/ProjectPropertyTypes"),
  },
  {
    path: "/developers/:developerId/developer-projects/:projectId/properties/:propertyId/packages",
    name: "PropertyPackage",
    component: () => import("../views/DeveloperPackages"),
  },
  {
    path: "/cart",
    name: "Cart",
    params: ["isWizard"],
    component: () => import("../views/Cart.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document
      .querySelectorAll(".parent-container__main, .parent-container__sidebar")
      .forEach((el) => {
        el.scroll({
          top: 0,
          behavior: "smooth",
        });
      });
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, _from, next) => {
  if (to.name === "StoreFront" || to.name === "About") {
    next();
  } else {
    store.dispatch("project/updateLastRouteVisited", to.name);
    next();
  }
});

export default router;

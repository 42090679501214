export const residenceTypes = [
  "Single Room",
  "Studio",
  "Small Apartment",
  "Big Apartment",
  "Penthouse",
  "Duplex",
  "Semi Attached Villa",
  "Villa",
];

export const categories = {
  network: {
    name: "Network",
    icon: "Network",
  },
  lighting: {
    name: "Lighting",
    icon: "Lighting",
  },
  sound: {
    name: "Audio System",
    icon: "Speakers",
  },
  home_theatre: {
    name: "Home Theatre",
    icon: "Home-Theatre",
  },
  climate: {
    name: "Air Conditioning",
    icon: "AC",
  },
  ambience: {
    name: "Shades / Curtains",
    icon: "Shades-Blinds",
  },
  safety: {
    name: "Safety",
    icon: "Security",
  },
  home: {
    name: "Home",
    icon: "Security",
  },
  wizard: {
    name: "Wizard",
    icon: "Security",
  },
};

export const defaultRoomSettings = {
  systems: [
    {
      id: 0,
      name: "Lighting",
      icon: "Lighting",
      active: true,
      questions: [
        {
          id: 0,
          text: "How many switches that you wish to control within this area?",
          type: "rating",
          answer: null,
        },
        {
          id: 1,
          text: "How many lamps in this space you wish to control",
          type: "rating",
          answer: null,
        },
        {
          id: 2,
          text: "Are you interested in using dimming features",
          type: "radio",
          options: ["Yes", "No"],
          answer: null,
        },
        {
          id: 3,
          text: "Would you like your lights triggered by motion detector for this space",
          type: "radio",
          options: ["Yes", "No"],
          answer: null,
        },
      ],
    },
    {
      id: 1,
      name: "Shades / Curtains",
      icon: "Shades-Blinds",
      active: true,
      questions: [
        {
          id: 1,
          text: "Do you currently have motorized curtains / shades in this area?",
          type: "radio",
          options: ["Yes", "No"],
          answer: null,
        },
        {
          id: 2,
          text: "How many motorized lines do you have in this space",
          type: "rating",
          answer: null,
        },
        {
          id: 3,
          text: "Would you like to add those to your control system",
          type: "radio",
          options: ["Yes", "No"],
          answer: null,
        },
      ],
    },
    {
      id: 2,
      name: "Air Conditioning",
      icon: "AC",
      active: false,
      questions: [
        {
          id: 1,
          text: "How many AC units do you have in this space",
          type: "rating",
          answer: null,
        },
      ],
    },
    {
      id: 3,
      name: "Home Theater",
      icon: "Home-Theatre",
      active: false,
      questions: [
        {
          id: 1,
          text: "Do you have a TV in this space?",
          type: "radio",
          options: ["Yes", "No"],
          answer: null,
        },
        {
          id: 2,
          text: "What is the size of your TV?",
          type: "radio",
          options: ['Less than 50"', 'More than 50"'],
          answer: null,
        },
        {
          id: 3,
          text: "Choose speaker options?",
          type: "image-select",
          options: [
            {
              id: 0,
              label: "Beam",
              image: "Dashboard/Speakers",
            },
            {
              id: 1,
              label: "Beam + Sub",
              image: "Dashboard/Speakers",
            },
            {
              id: 2,
              label: "Beam + Sub + 2x One",
              image: "Dashboard/Speakers",
            },
            {
              id: 3,
              label: "Arc",
              image: "Dashboard/Speakers",
            },
            {
              id: 4,
              label: "Arc + Sub",
              image: "Dashboard/Speakers",
            },
            {
              id: 5,
              label: "Arc + Sub + 2x One",
              image: "Dashboard/Speakers",
            },
          ],
          answer: null,
        },
      ],
    },
    {
      id: 4,
      name: "Audio System",
      icon: "Speakers",
      active: false,
      questions: [
        {
          id: 1,
          text: "Are you interested in adding music to this space",
          type: "radio",
          options: ["Yes", "No"],
          answer: null,
        },
        {
          id: 2,
          text: "Choose speaker options",
          type: "image-select",
          options: [
            {
              id: 0,
              label: "One",
              image: "Dashboard/Speakers",
            },
            {
              id: 1,
              label: "Five",
              image: "Dashboard/Speakers",
            },
            {
              id: 2,
              label: "Roam",
              image: "Dashboard/Speakers",
            },
            {
              id: 3,
              label: "Move",
              image: "Dashboard/Speakers",
            },
            {
              id: 4,
              label: "Sets",
              image: "Dashboard/Speakers",
            },
          ],
          answer: null,
        },
      ],
    },
  ],
};

export const wizardSteps = [
  {
    id: 0,
    image: "tech-bg",
    questions: [
      {
        id: 0,
        text: "Why are you interested in a home automation system",
        type: "radio",
        options: ["It's cool", "Convenience", "Energy Saving"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 1,
    image: "kids",
    questions: [
      {
        id: 0,
        text: "Do you have kids",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        rooms: [],
      },
      {
        id: 1,
        text: "Please specify their ages",
        type: "radio",
        options: ["+10 years", "-10 years"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 2,
    image: "gamers",
    questions: [
      {
        id: 0,
        text: "Is any of your households a gamer?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 3,
    image: "special-needs",
    questions: [
      {
        id: 0,
        text: "Does anyone in your household require special care?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
    ],
  },
  {
    id: 4,
    image: "party",
    questions: [
      {
        id: 0,
        text: "How often do you have gatherings at your residence?",
        type: "radio",
        options: ["Weekly", "Once a month"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 5,
    image: "remote-control",
    questions: [
      {
        id: 0,
        text: "Do you wish to control your Space 7 system remotely?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 6,
    image: "lighting",
    questions: [
      {
        id: 0,
        text: "Are lights sometimes left on in certain spaces?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
    ],
  },
  {
    id: 7,
    image: "ac",
    questions: [
      {
        id: 0,
        text: "In a hot summer day would you prefer to turn on the AC in specific places prior to arriving home?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
      {
        id: 1,
        text: "In your household, are your ACs left on unnecessarily",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
    ],
  },
  {
    id: 8,
    image: "blinds",
    questions: [
      {
        id: 0,
        text: "Do you wish to have your curtains / blinds behave in a certain manner based on different times of day offering privacy, heat control and scenery",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
    ],
  },
  {
    id: 9,
    image: "music",
    questions: [
      {
        id: 0,
        text: "Do you like to listen to music",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 10,
    image: "streaming-services",
    questions: [
      {
        id: 0,
        text: "How often do you watch movies / TV shows?",
        type: "radio",
        options: ["Daily", "Weekly", "Once a month"],
        answer: null,
        rooms: [],
      },
    ],
  },
  {
    id: 11,
    image: "voice",
    questions: [
      {
        id: 0,
        text: "Are you interested in using voice commands to control your system?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
    ],
  },
  {
    id: 12,
    image: "tech-bg",
    questions: [
      {
        id: 0,
        text: "Do you want to have a smoke detection system?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
      {
        id: 1,
        text: "Do you want to have a flood detection system?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
      {
        id: 2,
        text: "Do you want to have an intrusion detection system?",
        type: "radio",
        options: ["Yes", "No"],
        answer: null,
        selectRooms: true,
        rooms: [],
      },
    ],
  },
];

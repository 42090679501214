<template>
  <div
    id="modal"
    class="modal"
    :class="{ 'modal-background': !hideBackground }"
    v-if="show"
  >
    <div
      class="modal__body d-flex flex-column justify-content-center align-items-center"
    >
      <div class="d-flex modal__sticky-top" v-if="!hideClose">
        <v-icon
          class="text-neutral-light p-0 modal__close mb-5 mb-md-0"
          @click="closeClicked"
        >
          mdi-close
        </v-icon>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: ["show", "hideClose", "hideBackground"],
  methods: {
    setFullHeight() {
      this.$nextTick(() => {
        document.getElementById(
          "modal"
        ).style.height = `${window.innerHeight}px`;

        window.addEventListener("resize", () => {
          if (!this.show) {
            return;
          }
          document.getElementById(
            "modal"
          ).style.height = `${window.innerHeight}px`;
        });
      });
    },
    closeClicked() {
      this.$emit("close");
    },
  },
  mounted() {
    if (this.show) {
      this.setFullHeight();
    }
  },
  watch: {
    show(newVal) {
      if (newVal) {
        this.setFullHeight();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  padding: 2rem;
  width: 100%;
  display: block;

  &-background {
    background: rgba(0, 0, 0, 0.93);
  }

  &__body {
    opacity: 1;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 0.5rem;
    position: relative;
  }

  &__sticky-top {
    width: 100%;
    background: rgba(0, 0, 0, 0.93);
    position: sticky;
    top: 0;
  }

  &__close {
    font-size: 3rem !important;
    color: var(--neutral-light) !important;
    height: fit-content !important;
    display: block;
    margin-left: auto;
  }
}
</style>

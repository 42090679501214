import axios from "axios";

const state = {
  user: {},
};

const getters = {};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  removeUser(state) {
    state.user = {};
  },
};

const actions = {
  logOut({ commit }) {
    axios
      .delete(`/users/sign_out`, {
        withCredentials: true,
      })
      .then(() => {
        commit("removeUser");
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
